<template>
  <div class="hours-report-export-table">
    <div class="export-button-bar mb-2 center">
      <b-button @click="showModal('Excel')" variant="info">Export as Excel</b-button>
      <b-button @click="showModal('CSV'); showDownloadNameModal = true;" variant="info">Export as CSV</b-button>
      <b-modal
        v-model="showDownloadNameModal"
        :title="`Export as ${downloadFormat}`"
        ok-title="Save"
        @ok="exportToDownload"
        auto-focus-button="ok"
      >
        <b-container fluid>
          <label for="filename">Filename</label>
          <b-form-input v-model="exportFilename" id="filename" type="text"></b-form-input>
        </b-container>
      </b-modal>
    </div>
    <b-table :items="items" small></b-table>
  </div>
</template>

<script>
import XLSX from 'xlsx'

export default {
  props: ['items'],
  data: function () {
    return {
      defaultExportFilename: `hours_reports_${(new Date()).getFullYear()}_${(new Date()).getMonth() + 1}_${(new Date()).getDate()}`,
      displayFileNameForm: false,
      showDownloadNameModal: false,
      downloadFormat: "",
      exportFilename: ""
    }
  },
  methods: {
    showModal(format) {
      this.downloadFormat = format;
      if (this.exportFilename === "") {
        this.exportFilename = this.defaultExportFilename;
      }
      this.showDownloadNameModal = true;
    },
    exportToDownload() {
      if (this.downloadFormat === "Excel") {
        this.exportToExcel()
      } else if (this.downloadFormat === "CSV") {
        this.exportToCSV()
      }
    },
    exportToExcel() {
      // export json to Worksheet of Excel
      // only array possible
      var hoursRecords = XLSX.utils.json_to_sheet(this.items)

      var wb = XLSX.utils.book_new() // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, hoursRecords, 'Hours Data')

      // export Excel file
      XLSX.writeFile(wb, `${this.exportFilename}.xlsx`)
    },
    exportToCSV() {
      // export json to Worksheet of Excel
      // only array possible
      var hoursRecords = XLSX.utils.json_to_sheet(this.items)

      var wb = XLSX.utils.book_new() // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, hoursRecords, 'Hours Data')

      // export CSV file
      XLSX.writeFile(wb, `${this.exportFilename}.csv`)
    }
  }
}
</script>

<style>
</style>
