<template>
  <div class="pto-account-export-table">
    <div class="export-button-bar  mb-2 center">
      <b-button @click="showModal('Excel')" variant="info">Export as Excel</b-button>
      <b-button @click="showModal('CSV'); showDownloadNameModal = true;" variant="info">Export as CSV</b-button>
      <b-modal
        v-model="showDownloadNameModal"
        :title="`Export as ${downloadFormat}`"
        ok-title="Save"
        @ok="exportToDownload"
        auto-focus-button="ok"
      >
        <b-container fluid>
          <label for="filename">Filename</label>
          <b-form-input v-model="exportFilename" id="filename" type="text"></b-form-input>
        </b-container>
      </b-modal>
    </div>

    <div class="mx-4">
      <b-table :items="items" :fields="formattedFields" small></b-table>
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx'

export default {
  props: ['fields', 'items', 'setName'],
  data: function () {
    return {
      defaultExportFilename: `pto_accounts_summary_${(new Date()).getFullYear()}_${(new Date()).getMonth() + 1}_${(new Date()).getDate()}`,
      displayFileNameForm: false,
      showDownloadNameModal: false,
      downloadFormat: "",
      exportFilename: ""
    }
  },
  computed: {
    formattedFields: function () {
      var formattedFields = [];
      this.fields.forEach((field) => {
        if (typeof field === typeof {}) {
          if (!field.formatter) {
            field.formatter = (value) => (typeof value !== "number" || value === "") ? value : Math.round(value * 100) / 100;
          }
          formattedFields.push(field);
        } else {
          var fieldObject = {key: field, label: field, formatter: (value) => (typeof value !== "number" || value === "") ? value : Math.round(value * 100) / 100};
          formattedFields.push(fieldObject);
        }
      });
      return formattedFields
    },
    orderedFields: function () {
      var orderedFields = [];
      this.formattedFields.forEach((field) => {
        if (typeof field === typeof {}) {
          orderedFields.push(field.key);
        } else {
          orderedFields.push(field);
        }
      });
      return orderedFields;
    },
    labels: function () {
      var labels = [];
      this.formattedFields.forEach((field) => {
        if (typeof field === typeof {}) {
          field.label ? labels.push(field.label) : labels.push(field.key);
        } else {
          labels.push(field);
        }
      });
      return labels;
    },
    labelledItems: function () {
      var labelledItems = []
      this.items.forEach((item) => {
        labelledItems.push(this.toLabelledItem(item));
      });
      return labelledItems;
    }
  },
  methods: {
    getJSONValueFromPath(item, path) {
      return path.split('.').reduce((subItem, key) => subItem ? subItem[key] : null, item);
    },
    toLabelledItem(item) {
      let labelledItem = {};
      this.formattedFields.forEach((field) => {
        let label = 'ERROR';
        if (typeof field === typeof {}) {
          label = field.label ? field.label : field.key;
        } else {
          label = field;
        }
        let key = 'ERROR';
        if (typeof field === typeof {}) {
          key = field.key;
        } else {
          key = field;
        }
        labelledItem[label] = this.getJSONValueFromPath(item, key);
      });
      return labelledItem;
    },
    showModal(format) {
      this.downloadFormat = format;
      if (this.exportFilename === "") {
        this.exportFilename = this.defaultExportFilename;
      }
      this.showDownloadNameModal = true;
    },
    exportToDownload() {
      if (this.downloadFormat === "Excel") {
        this.exportToExcel()
      } else if (this.downloadFormat === "CSV") {
        this.exportToCSV()
      }
    },
    exportToExcel() {
      // export json to Worksheet of Excel
      // only array possible
      var ptoRecords = XLSX.utils.json_to_sheet(this.labelledItems, {header: this.labels})

      var wb = XLSX.utils.book_new() // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, ptoRecords, this.setName)

      // export Excel file
      XLSX.writeFile(wb, `${this.exportFilename}.xlsx`)
    },
    exportToCSV() {
      // export json to Worksheet of Excel
      // only array possible
      var ptoRecords = XLSX.utils.json_to_sheet(this.labelledItems, {header: this.labels})

      var wb = XLSX.utils.book_new() // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, ptoRecords, this.setName)

      // export CSV file
      XLSX.writeFile(wb, `${this.exportFilename}.csv`)
    }
  }
}
</script>

<style>
</style>
