<template>
  <div class="team-member-table">
    <b-row>
      <b-col lg="3" class="my-1">
        <b-form-group
          label="Search"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col lg="3" class="my-1"> </b-col>
      <b-col lg="2" class="my-1">
        <b-form-group
          v-if="totalRows > minPaginationItems"
          label="Per page"
          label-for="per-page-select"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col lg="4" class="my-1">
        <b-pagination
          v-if="totalRows > minPaginationItems"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
    <b-table
      :fields="adjustedFields"
      :items="items"
      :filter="filter"
      :filter-included-fields="filterOn"
      :current-page="currentPage"
      :per-page="perPage"
      @filtered="onFiltered"
      sort-icon-left
      small
      responsive
    >
      <template #cell(first_and_nick_name)="row">
        <a :href="`/team_members/${row.item.id}`">{{ row.value }}</a>
      </template>
      <template #cell(last_name)="row">
        <a :href="`/team_members/${row.item.id}`">{{ row.value }}</a>
      </template>
      <template #cell(supervisors_object)="row">
        <span
          v-for="supervisor in getSortedSupervisors(
            row.item.supervisors_object
          )"
          :key="supervisor.id"
        >
          <a :href="`/team_members/${supervisor.id}`">{{ supervisor.name }}</a
          ><br />
        </span>
      </template>
      <template #cell(enabled?)="row">
        <span v-if="row.value">✔</span>
      </template>
      <template #cell(activated?)="row">
        <span v-if="row.value">✔</span>
      </template>
      <template #cell(supervised?)="row">
        <span v-if="row.value">✔</span>
      </template>
      <template #cell(edit_member)="row">
        <a
          :href="
            `/team_members/${row.item.id}/edit?return_path=%2Fteam_members`
          "
          >edit</a
        >
      </template>
      <template #cell(delete_member)="row">
        <a
          v-if="row.item.id != current_user_id"
          :data-confirm="
            `Are you sure you want to delete ${row.item.first_and_nick_name} ${row.item.last_name}?`
          "
          rel="nofollow"
          data-method="delete"
          :href="`/team_members/${row.item.id}?return_path=%2Fteam_members`"
          >delete</a
        >
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  props: ["fields", "items", "current_user_id"],
  data: function() {
    const initialPerPage = 100;
    return {
      filter: null,
      filterOn: [
        "first_and_nick_name",
        "last_name",
        "email",
        "alternate_names",
      ],
      currentPage: 1,
      perPage: initialPerPage,
      pageOptions: [
        10,
        20,
        50,
        100,
        { value: this.items.length, text: "Show all" },
      ],
      totalRows: this.items.length,
      minPaginationItems: initialPerPage,
    };
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getSortedSupervisors(supervisors) {
      const sortedSupervisors = supervisors.map((supervisor) => ({
        name: supervisor.name,
        id: supervisor.id,
      }));
      sortedSupervisors.sort((a, b) => (a.name < b.name ? -1 : b < a ? 1 : 0));
      return sortedSupervisors;
    },
  },
  computed: {
    rows() {
      return this.totalRows;
    },
    adjustedFields() {
      const adjustedFields = [...this.fields].filter(
        (field) => field !== "alternate_names"
      );
      const supervisorsField = adjustedFields.find(
        (field) => field.key === "supervisors_object"
      );
      if (supervisorsField) {
        supervisorsField.formatter = (value, key, item) =>
          value.map((supervisor) => supervisor.name).sort();
        supervisorsField.sortByFormatted = true;
      }
      return adjustedFields;
    },
  },
};
</script>

<style>
.team-member-table td {
  font-size: 0.9rem;
}
</style>
