<template>
  <div class="qualification-page">
    <div class="container w-50">
      <h1>Qualifications</h1>
      <div class="center" v-if="userIsAdmin">
        <b-button @click="displayForm()" v-if="showAddButton"
          >Add Qualification</b-button
        >
        <div v-else>
          <b-form-input
            id="input-1"
            v-model="form.name"
            type="text"
            placeholder="Enter name"
            required
            @keydown.native.enter="onSubmit()"
          ></b-form-input>
          <b-button
            v-if="isSavingNewAddition == false"
            @click="onSubmit()"
            variant="primary"
            >Submit</b-button
          >
          <b-button v-else variant="primary"
            ><b-spinner small variant="white" label="Saving..."></b-spinner
          ></b-button>
          <b-button @click="onCancel()">Cancel</b-button>
        </div>
      </div>
      <br />
      <div class="qualification-table mt-4">
        <b-table
          :fields="fields"
          :items="items"
          tbody-tr-class="qualification-table-row"
          small
          sort-by="name"
          sort-icon-left
        >
          <template #cell(name)="row">
            <span v-if="row.item.makeEdit == false">{{ row.item.name }}</span>
            <span v-else class="input-group">
              <b-form-input
                size="sm"
                v-model="row.item.edittedName"
                :value="`${row.item.name}`"
                @keydown.native.enter="saveEdit(row)"
              />
              <span class="input-group-btn">
                <b-button
                  v-if="row.item.isSavingEdit == false"
                  variant="primary"
                  @click="saveEdit(row)"
                  >Save</b-button
                >
                <b-button v-else variant="primary"
                  ><b-spinner
                    small
                    variant="white"
                    label="Text Centered"
                  ></b-spinner
                ></b-button>
              </span>
            </span>
          </template>
          <template #cell(edit_and_delete_qualification)="row">
            <span class="input-group" v-if="userIsAdmin">
              <b-button
                variant="link"
                v-if="row.item.makeEdit == false"
                @click="editQualification(row)"
                ><b-icon variant="secondary" icon="pencil-fill"
              /></b-button>
              <b-button variant="link" v-else @click="cancelEdit(row)"
                ><b-icon variant="secondary" icon="x-circle"
              /></b-button>
              <b-button variant="link" @click="showDeleteModal(row)"
                ><b-icon
                  variant="secondary"
                  icon="trash-fill"
                  aria-hidden="true"
              /></b-button>
            </span>
          </template>
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :class="`qualification-table-${field.key}`"
            />
          </template>
        </b-table>
      </div>
    </div>
    <b-modal
      id="confirm-delete-modal"
      title="Confirm Deletion"
      @ok="deleteQualification(selectedQualification)"
      header-bg-variant="danger"
      header-text-variant="white"
      ok-variant="danger"
      ok-title="Delete"
    >
      <p class="my-4">
        Are you sure you want to delete the
        {{ selectedQualification.name }} qualification?
      </p>
    </b-modal>
    <b-modal
      id="error-modal"
      header-bg-variant="danger"
      ok-variant="danger"
      ok-title="Dismiss"
      header-text-variant="white"
      ok-only
    >
      <template #modal-header>
        <h4>
          <b-icon icon="exclamation-triangle-fill" /> Error: {{ errorMessage }}
        </h4>
      </template>
      <p v-if="errorDetails !== []">
        Encountered {{ errorDetails.length }} error{{
          errorDetails.length > 1 ? "s" : ""
        }}:
      </p>
      <b-list-group v-if="errorDetails !== []">
        <b-list-group-item v-for="detail in errorDetails" :key="detail">
          {{ detail }}
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </div>
</template>

<script>
var axios = require("axios").default;
export default {
  props: ["userIsAdmin"],
  data: function() {
    return {
      qualifications: [],
      fields: [
        { key: "name", sortable: true },
        { key: "edit_and_delete_qualification", label: "" },
      ],
      items: [],
      form: {
        name: "",
      },
      showAddButton: true,
      isSavingNewAddition: false,
      selectedQualification: {},
      errorMessage: "A server error occurred",
      errorDetails: [],
    };
  },
  methods: {
    async getQualifications() {
      this.qualifications = await axios.get("/api/v1/qualifications");
    },
    displayForm() {
      this.showAddButton = false;
      this.isSavingNewAddition = false;
    },
    editQualification(row) {
      row.item.makeEdit = true;
    },
    showDeleteModal(row) {
      this.selectedQualification = row.item;
      this.$bvModal.show("confirm-delete-modal");
    },
    deleteQualification(qualification) {
      this.errorDetails = [];
      axios
        .delete("/api/v1/qualifications/" + String(qualification.id))
        .then(() => {
          //Remove deleted object from b-table items
          this.items = this.items.filter(
            (item) => item.id !== qualification.id
          );
        })
        .catch((errors) => {
          if (!errors.response) {
            this.errorMessage = "Failed to reach server.";
            this.$bvModal.show("error-modal");
          } else {
            this.errorDetails = this.formatDetails(
              errors.response.data.error.base
            );
            this.errorMessage = `Failed to delete ${qualification.name}.`;
            this.$bvModal.show("error-modal");
          }
        });
    },
    cancelEdit(row) {
      row.item.makeEdit = false;
      row.item.edittedName = row.item.name;
    },
    saveEdit(row) {
      this.errorDetails = [];
      row.item.isSavingEdit = true;
      axios
        .put("/api/v1/qualifications/" + String(row.item.id), {
          name: row.item.edittedName,
        })
        .then(() => {
          row.item.isSavingEdit = false;
          row.item.makeEdit = false;
          row.item.name = row.item.edittedName; //So current table updates without page refresh
        })
        .catch((errors) => {
          row.item.isSavingEdit = false;
          if (!errors.response) {
            this.errorMessage = "Failed to reach server.";
            this.$bvModal.show("error-modal");
          } else {
            this.errorDetails = this.formatDetails(errors.response.data.error);
            this.errorMessage = "Failed to save qualification.";
            this.$bvModal.show("error-modal");
          }
        });
    },
    onSubmit() {
      this.errorDetails = [];
      this.isSavingNewAddition = true;
      axios
        .post("/api/v1/qualifications", { name: this.form.name })
        .then((response) => {
          this.items.push(
            this.createNewItem(response.data._id.$oid, response.data.name)
          );
          this.showAddButton = true;
          this.form.name = "";
          this.isSavingNewAddition = false;
        })
        .catch((errors) => {
          this.isSavingNewAddition = false;
          if (!errors.response) {
            this.errorMessage = "Failed to reach server.";
            this.$bvModal.show("error-modal");
          } else {
            this.errorDetails = this.formatDetails(errors.response.data.error);
            this.errorMessage = "Failed to save qualification.";
            this.$bvModal.show("error-modal");
          }
        });
    },
    formatDetails(details) {
      if (Array.isArray(details)) {
        return details;
      } else if (typeof details === typeof {}) {
        var detailsArray = [];
        Object.entries(details).forEach((entry) => {
          const [key, value] = entry;
          if (Array.isArray(value)) {
            value.forEach((message) => {
              detailsArray.push(`${key} ${message}`);
            });
          } else {
            detailsArray.push(`${key} ${value}`);
          }
        });
        return detailsArray;
      } else {
        return [details];
      }
    },
    onCancel() {
      this.showAddButton = true;
      this.form.name = "";
    },
    createNewItem(id, name) {
      return {
        id: id,
        name: name,
        makeEdit: false,
        edittedName: name,
        isSavingEdit: false,
      };
    },
    getCSRFToken() {
      return document.querySelector("[name=csrf-token]").content;
    },
    setRequestHeaders() {
      axios.defaults.headers.common["X-CSRF-TOKEN"] = this.getCSRFToken();
    },
  },
  created: function() {
    this.setRequestHeaders();
    this.getQualifications()
      .then(() => {
        for (var i = 0; i < this.qualifications.data.length; i++) {
          this.items.push(
            this.createNewItem(
              this.qualifications.data[i]._id.$oid,
              this.qualifications.data[i].name
            )
          );
        }
      })
      .catch((errors) => {
        console.log(errors);
      });
  },
};
</script>

<style>
.qualification-table {
  margin-left: auto;
  margin-right: auto;
  width: 20rem;
}
.qualification-table-row > td {
  vertical-align: middle !important;
}
.qualification-table-edit_and_delete_qualification {
  width: 7rem;
}
</style>
