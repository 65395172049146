<template>
  <span
    class="qualification-error-indicator"
    v-if="missingQualificationCount > 0"
  >
    <b-icon :variant="missingFullProjectRestriction ? 'danger' : 'warning'" :id="id" icon="exclamation-triangle"></b-icon>
    <b-popover
      :target="id"
      triggers="hover"
      :variant="missingFullProjectRestriction ? 'danger' : 'warning'"
      placement="top"
      boundary="window"
      title="Project Restrictions:"
    >
      <div class="h5 pb-0 mb-0">
        <b-badge
          v-for="restriction in sortedRestrictions"
          :key="restriction.id.$oid"
          :variant="qualificationVariant[restriction.qualification.id.$oid]"
          class="mr-1"
        >
          <b-icon
            scale="1.5"
            :icon="
              qualificationVariant[restriction.qualification.id.$oid] === 'primary'
                ? 'check'
                : 'x'
            "
          ></b-icon>
          {{ restriction.qualification.name }}
        </b-badge>
      </div>
    </b-popover>
  </span>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    projectRestrictions: {
      type: Array,
      required: true,
    },
    teamMemberQualifications: {
      type: Array,
      required: true,
    },
  },
  data: function() {
    return {
      qualificationVariant: {},
      missingQualificationCount: 0,
      missingFullProjectRestriction: false
    };
  },
  computed: {
    sortedRestrictions(){
      return this.projectRestrictions.sort((a, b) => a.qualification.name.localeCompare(b.qualification.name) )
    }
  },
  methods: {
    findMissingQualifications() {
      this.qualificationVariant = {};
      this.missingQualificationCount = 0;
      var teamMemberQualificationIDs = this.teamMemberQualifications.map(
        (qualification) => qualification._id.$oid
      );
      for (var projectRestriction of this.projectRestrictions) {
        var hasQualification = teamMemberQualificationIDs.includes(
          projectRestriction.qualification.id.$oid
        );
        if (hasQualification === false) {
          if(projectRestriction.coverage === "full_project") {
            this.missingFullProjectRestriction = true;
            this.qualificationVariant[projectRestriction.qualification.id.$oid] = "danger";
          } else {
            this.qualificationVariant[projectRestriction.qualification.id.$oid] = "warning";
          }
          this.missingQualificationCount += 1;
        } else {
          this.qualificationVariant[projectRestriction.qualification.id.$oid] = "primary";
        }
      }
    }
  },
  created: function() {
    this.findMissingQualifications();
  },
};
</script>

<style></style>
