<template>
  <div class="effort-list-export-table">
    <b-row>
      <b-col lg="3" class="my-1">
        <b-form-group
          label="Search"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col lg="3" class="my-1">
        <b-form-group
          label="Location"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="filter-location"
              v-model="locationFilter"
              :options="locationFilters"
            ></b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col lg="6" class="my-1" />
    </b-row>

    <div>
      <b-row>
        <b-col lg="3" class="my-1">
          <h4>Team Members</h4>
        </b-col>
        <b-col lg="3" class="my-1" />
        <b-col lg="2" class="my-1">
          <b-form-group
            v-if="filteredTeamMembers.length > minPaginationTeamMember"
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="teamMemberPerPage"
              :options="teamMemberPageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col lg="4" class="my-1">
          <b-pagination
            v-if="filteredTeamMembers.length > minPaginationTeamMember"
            v-model="teamMemberCurrentPage"
            :total-rows="filteredTeamMembers.length"
            :per-page="teamMemberPerPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
      <b-table
        v-if="filteredTeamMembers.length > 0"
        :items="filteredTeamMembers"
        :fields="teamMemberFields"
        :filter="filter"
        :filter-included-fields="teamMemberFilterOn"
        :current-page="teamMemberCurrentPage"
        :per-page="teamMemberPerPage"
        @filtered="onFiltered"
        sort-icon-left
        small
        responsive
      ></b-table>
      <div v-else>
        <hr />
        <span class="center">No team members were found.</span>
        <hr />
      </div>
    </div>
    <hr />
    <div>
      <b-row>
        <b-col lg="3" class="my-1">
          <h4>Locations</h4>
        </b-col>
        <b-col lg="3" class="my-1" />
        <b-col lg="2" class="my-1">
          <b-form-group
            v-if="locationTotalRows > minPaginationLocation"
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="locationPerPage"
              :options="locationPageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col lg="4" class="my-1">
          <b-pagination
            v-if="locationTotalRows > minPaginationLocation"
            v-model="locationCurrentPage"
            :total-rows="locationTotalRows"
            :per-page="locationPerPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
      <b-table
        v-if="filteredLocations.length > 0"
        :items="filteredLocations"
        :fields="locationFields"
        :filter="filter"
        :filter-included-fields="locationFilterOn"
        :current-page="locationCurrentPage"
        :per-page="locationPerPage"
        @filtered="onFiltered"
        sort-icon-left
        small
        responsive
      >
        <template #cell(address)="row">
          <template v-if="!row.item.makeEdit">
            {{ row.value.line1 }}<br v-if="row.value.line1" />
            {{ row.value.line2 }}<br v-if="row.value.line2" />
            {{ row.value.line3 }}<br v-if="row.value.line3" />
            {{ row.value.line4 }}<br v-if="row.value.line4" />
            {{ row.value.line5 }}<br v-if="row.value.line5" />
          </template>
        </template>
      </b-table>
      <div v-else>
        <hr />
        <span class="center">No team members were found.</span>
        <hr />
      </div>
    </div>
    <div class="export-button-bar  mb-2 center">
      <b-button @click="showModal('Excel')" variant="info"
        >Export as Excel</b-button
      >
      <b-button
        @click="
          showModal('CSV');
          showDownloadNameModal = true;
        "
        variant="info"
        >Export as CSV</b-button
      >
      <b-modal
        v-model="showDownloadNameModal"
        :title="`Export as ${downloadFormat}`"
        ok-title="Save"
        @ok="exportToDownload"
        auto-focus-button="ok"
      >
        <b-container fluid>
          <label for="filename">Filename</label>
          <b-form-input
            v-model="exportFilename"
            id="filename"
            type="text"
          ></b-form-input>
        </b-container>
      </b-modal>
    </div>
  </div>
</template>

<script>
import XLSX from "xlsx";

export default {
  props: ["teamMembers", "locations"],
  data: function() {
    const initialTeamMemberPerPage = 100;
    const initialLocationPerPage = 100;
    return {
      defaultExportFilename: `contacts_${new Date().getFullYear()}_${new Date().getMonth() +
        1}_${new Date().getDate()}`,
      displayFileNameForm: false,
      showDownloadNameModal: false,
      downloadFormat: "",
      exportFilename: "",
      locationFilter: "All",
      locationFilters: [
        "All",
        ...this.locations.map((location) => location.name),
        "None",
      ],
      filter: null,
      teamMemberFields: [
        {
          key: "first_and_nick_name",
          label: "First Name",
          sortable: true,
        },
        {
          key: "last_name",
          sortable: true,
        },
        {
          key: "location",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        {
          key: "phone_office",
          label: "Office",
          sortable: true,
        },
        {
          key: "phone_mobile",
          label: "Mobile",
          sortable: true,
        },
      ],
      teamMemberFilterOn: [
        "first_and_nick_name",
        "last_name",
        "email",
        "location",
        "phone_office",
        "phone_mobile",
        "alternate_names",
      ],
      teamMemberCurrentPage: 1,
      teamMemberPerPage: initialTeamMemberPerPage,
      teamMemberPageOptions: [
        10,
        20,
        50,
        100,
        { value: this.teamMembers.length, text: "Show all" },
      ],
      teamMemberTotalRows: this.teamMembers.length,
      minPaginationTeamMember: initialTeamMemberPerPage,
      locationFields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "address",
        },
      ],
      locationFilterOn: ["name", "address"],
      locationCurrentPage: 1,
      locationPerPage: initialLocationPerPage,
      locationPageOptions: [
        10,
        20,
        50,
        100,
        { value: this.locations.length, text: "Show all" },
      ],
      locationTotalRows: this.locations.length,
      minPaginationLocation: initialLocationPerPage,
    };
  },
  computed: {
    filteredTeamMembers() {
      // When location filter changes, reset pagination page
      this.teamMemberCurrentPage = 1;
      if (this.locationFilter === "All") {
        return this.teamMembers.sort(this.sortRows);
      } else if (this.locationFilter === "None") {
        return this.teamMembers
          .filter(
            (teamMember) =>
              !teamMember.location || teamMember.location === "None"
          )
          .sort(this.sortRows);
      } else {
        return this.teamMembers
          .filter((teamMember) => teamMember.location === this.locationFilter)
          .sort(this.sortRows);
      }
    },
    filteredLocations() {
      this.locationCurrentPage = 1;
      if (this.locationFilter === "All") {
        return this.locations.sort((a, b) => a.name.localeCompare(b.name));
      } else if (this.locationFilter === "None") {
        return this.locations
          .filter((location) => !location.name || location.name === "None")
          .sort((a, b) => a.name.localeCompare(b.name));
      } else {
        return this.locations
          .filter((location) => location.name === this.locationFilter)
          .sort((a, b) => a.name.localeCompare(b.name));
      }
    },
    filteredLocationsCount() {
      this.filteredLocations.length;
    },
  },
  methods: {
    showModal(format) {
      this.downloadFormat = format;
      if (this.exportFilename === "") {
        this.exportFilename = this.defaultExportFilename;
      }
      this.showDownloadNameModal = true;
    },
    exportToDownload() {
      if (this.downloadFormat === "Excel") {
        this.exportToExcel();
      } else if (this.downloadFormat === "CSV") {
        this.exportToCSV();
      }
    },
    exportToExcel() {
      // export json to Worksheet of Excel
      // only array possible
      var contactRecords = XLSX.utils.json_to_sheet(
        this.convertTeamMembersForExport(this.filteredTeamMembers)
      );

      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, contactRecords, "Contacts");

      // export Excel file
      XLSX.writeFile(wb, `${this.exportFilename}.xlsx`);
    },
    exportToCSV() {
      // export json to Worksheet of Excel
      // only array possible
      var contactRecords = XLSX.utils.json_to_sheet(
        this.convertTeamMembersForExport(this.filteredTeamMembers)
      );

      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, contactRecords, "Contacts");

      // export CSV file
      XLSX.writeFile(wb, `${this.exportFilename}.csv`);
    },
    convertTeamMembersForExport(teamMembers) {
      if (teamMembers[0]?.supervisors) {
        return teamMembers.map((teamMember) => ({
          "First Name": teamMember.first_and_nick_name,
          "Last Name": teamMember.last_name,
          Location: teamMember.location,
          email: teamMember.email,
          office: teamMember.phone_office,
          mobile: teamMember.phone_mobile,
          supervisor: teamMember?.supervisors[0]?.display_name,
          "Supervisor Email": teamMember?.supervisors[0]?.email,
          "Supervisor 2": teamMember?.supervisors[1]?.display_name,
          "Supervisor 2 Email": teamMember?.supervisors[1]?.email,
        }));
      } else {
        return teamMembers.map((teamMember) => ({
          "First Name": teamMember.first_and_nick_name,
          "Last Name": teamMember.last_name,
          Location: teamMember.location,
          email: teamMember.email,
          office: teamMember.phone_office,
          mobile: teamMember.phone_mobile,
        }));
      }
    },
    onFiltered(filteredTeamMembers) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.teamMemberTotalRows = filteredTeamMembers.length;
      this.teamMemberCurrentPage = 1;
    },
  },
};
</script>

<style></style>
