<template>
  <input
    ref="expenseInput"
    type="text"
    :class="`form-control timeEntryInput ${statusClass}`"
    :disabled="disabled"
    :tabindex="tabIndex"
    v-model="amount"
    @input="debouncedOnAmountInput"
    @change="test($event)"
  />
</template>

<script>
var debounce = require("lodash.debounce");
var axios = require("axios").default;
export default {
  props: [
    "timesheetId",
    "assignmentId",
    "date",
    "unit",
    "initialAmount",
    "disabled",
    "tabIndex",
  ],
  data: function() {
    return {
      amount: this.initialAmount,
      lastSavedAmount: this.initialAmount,
      errorMessage: "",
      errorDetails: [],
      debouncedOnAmountInput: debounce(this.onAmountInput, 1000),
      statusClass: "",
    };
  },
  methods: {
    formatDetails(details) {
      if (Array.isArray(details)) {
        return details;
      } else if (typeof details === typeof {}) {
        var detailsArray = [];
        Object.entries(details).forEach((entry) => {
          const [key, value] = entry;
          if (Array.isArray(value)) {
            value.forEach((message) => {
              detailsArray.push(`${key} ${message}`);
            });
          } else {
            detailsArray.push(`${key} ${value}`);
          }
        });
        return detailsArray;
      } else {
        return [details];
      }
    },
    onAmountInput() {
      this.statusClass = "";
      this.errorDetails = [];
      if (!this.disabled) {
        if (isNaN(this.amount)) {
          this.amount = this.lastSavedAmount ? this.lastSavedAmount : "";
          return;
        }
        axios
          .patch(`/api/v1/timesheets/${this.timesheetId}`, {
            updateType: "updateExpenses",
            expense: {
              assignment: this.assignmentId,
              date: this.date,
              unit: this.unit,
              amount: this.amount,
            },
          })
          .then((response) => {
            this.statusClass = "success";
            setTimeout(() => (this.statusClass = ""), 200);
            this.lastSavedAmount = response.data.expense.amount;
          })
          .catch((error) => {
            this.statusClass = "failure";
            if (!error.response) {
              this.errorMessage =
                "Could not connect to server: Please try again";
              alert(this.errorMessage);
            } else {
              this.errorDetails = this.formatDetails(error.response.data.error);
              this.errorMessage = "Failed to save hours";
              alert(`${this.errorMessage}: ${this.errorDetails}`);
            }
            this.amount = this.lastSavedAmount ? this.lastSavedAmount : "";
            setTimeout(() => {
              this.$refs.expenseInput.dispatchEvent(
                new Event("change", {
                  bubbles: true,
                })
              );
            }, 10);
          });
      }
    },
    test(event) {
      console.log(event);
    },
    getCSRFToken() {
      return document.querySelector("[name=csrf-token]").content;
    },
    setRequestHeaders() {
      axios.defaults.headers.common["X-CSRF-TOKEN"] = this.getCSRFToken();
    },
  },
  created: function() {
    this.setRequestHeaders();
  },
};
</script>

<style></style>
