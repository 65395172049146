<template>
  <div class="attestation ml-3">
    <b-checkbox
      v-model="checked"
      value="confirmed"
      unchecked-value="not confirmed"
      @change="onStatusUpdate"
      :disabled="disabled"
      >{{ statement }}</b-checkbox
    >
    <b-modal
      :id="`error-modal-${name}`"
      header-bg-variant="danger"
      ok-variant="danger"
      ok-title="Dismiss"
      header-text-variant="white"
      ok-only
    >
      <template #modal-header>
        <h4>
          <b-icon icon="exclamation-triangle-fill" /> Error: {{ errorMessage }}
        </h4>
      </template>
      <p v-if="errorDetails !== []">
        Encountered {{ errorDetails.length }} error{{
          errorDetails.length > 1 ? "s" : ""
        }}:
      </p>
      <b-list-group v-if="errorDetails !== []">
        <b-list-group-item v-for="detail in errorDetails" :key="detail">
          {{ detail }}
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </div>
</template>

<script>
var axios = require("axios").default;
export default {
  props: [
    "timesheetId",
    "name",
    "statement",
    "initialStatus",
    "disabled",
    "done",
  ],
  data: function() {
    return {
      checked: this.initialStatus,
      lastSavedChecked: this.initialStatus,
      errorMessage: "A server error occurred",
      errorDetails: [],
    };
  },
  methods: {
    formatDetails(details) {
      if (Array.isArray(details)) {
        return details;
      } else if (typeof details === typeof {}) {
        var detailsArray = [];
        Object.entries(details).forEach((entry) => {
          const [key, value] = entry;
          if (Array.isArray(value)) {
            value.forEach((message) => {
              detailsArray.push(`${key} ${message}`);
            });
          } else {
            detailsArray.push(`${key} ${value}`);
          }
        });
        return detailsArray;
      } else {
        return [details];
      }
    },
    onStatusUpdate() {
      this.errorDetails = [];
      axios
        .patch(`/api/v1/timesheets/${this.timesheetId}`, {
          attestation: {
            name: this.name,
            statement: this.statement,
            status: this.checked,
          },
          updateType: "updateAttestation",
        })
        .then((response) => {
          console.log(response);
          this.lastSavedChecked = this.checked;
        })
        .catch((errors) => {
          if (!errors.response) {
            this.errorMessage = "Failed to reach server.";
            this.$bvModal.show(`error-modal-${this.name}`);
          } else {
            this.errorDetails = this.formatDetails(errors.response.data.error);
            this.errorMessage = "Failed to save attestation.";
            this.$bvModal.show(`error-modal-${this.name}`);
          }
          this.checked = this.lastSavedChecked;
        });
    },
    getCSRFToken() {
      return document.querySelector("[name=csrf-token]").content;
    },
    setRequestHeaders() {
      axios.defaults.headers.common["X-CSRF-TOKEN"] = this.getCSRFToken();
    },
  },
  created: function() {
    this.setRequestHeaders();
  },
};
</script>

<style></style>
