<template>
  <div class="hours-report-page">
    <h1>Hours Report</h1>
    <div style="width: 20%; margin: 0 auto; margin-bottom: 1rem;">
      <b-form-select @keydown.native.enter="getReport" v-model="selectedMonth" :options="months" />
      <b-form-input @keydown.native.enter="getReport" type="number" v-model="selectedYear" :options="years" />
      <b-form-select @keydown.native.enter="getReport" v-model="selectedPeople" :options="people" multiple :select-size="Math.min(10, people.length)"/>
      <div class="center">
        <b-button v-if="!querying" @click="getReport" variant="outline-primary">Query</b-button>
        <b-button v-else variant="outine-primary"><b-spinner small variant="primary" label="Text Centered"></b-spinner></b-button>
      </div>
    </div>
    <div v-if="queried && !querying">
      <h3 class="text-center">Results</h3>
      <hours-report-export-table v-if="report.length > 0" :items="report" small />
      <p class="center" v-else-if="error !== ''">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import HoursReportExportTable from "./HoursReportExportTable.vue"

export default {
  props: {people: {default: function () { return [] }}},
  components: {
    "hours-report-export-table": HoursReportExportTable
  },
  data: function () {
    return {
      months: [
        {value: 1, text: "January"},
        {value: 2, text: "February"},
        {value: 3, text: "March"},
        {value: 4, text: "April"},
        {value: 5, text: "May"},
        {value: 6, text: "June"},
        {value: 7, text: "July"},
        {value: 8, text: "August"},
        {value: 9, text: "September"},
        {value: 10, text: "October"},
        {value: 11, text: "November"},
        {value: 12, text: "December"}
      ],
      years: [
        {value: 1, text: "January"},
        {value: 2, text: "February"},
        {value: 3, text: "March"},
        {value: 4, text: "April"},
        {value: 5, text: "May"},
        {value: 6, text: "June"},
        {value: 7, text: "July"},
        {value: 8, text: "August"},
        {value: 9, text: "September"},
        {value: 10, text: "October"},
        {value: 11, text: "November"},
        {value: 12, text: "December"}
      ],
      selectedYear: (new Date()).getFullYear(),
      selectedMonth: (new Date()).getMonth() + 1,
      selectedPeople: [],
      report: [],
      error: "",
      querying: false,
      queried: false
    }
  },
  methods: {
    getCSRFToken() {
      return document.querySelector('[name=csrf-token]').content
    },
    setRequestHeaders() {
      axios.defaults.headers.common['X-CSRF-TOKEN'] = this.getCSRFToken();
      axios.defaults.headers.common['Accept'] = 'application/json';
      axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    },
    getReport() {
      this.querying = true
      this.report = []
      this.error = ""
      this.setRequestHeaders()
      axios.post("/allocator/hours_report_request", {date: {year: this.selectedYear, month: this.selectedMonth}, team_member_id: this.selectedPeople}).then(response => {
        if ("report" in response.data) {
          this.report = response.data.report
        } else if ("error" in response.data) {
          this.error = response.data.error
        } else {
          this.error = "Unrecognized response from server. Please try again."
        }
      }).catch(response => {
        console.log("There was an issue reaching the server. Please try again.");
      }).finally(() => {
        this.querying = false
        this.queried = true
      })
    }
  }
}
</script>

<style>
</style>
