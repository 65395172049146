<template>
  <div class="team-member-audit-export-table">
    <b-row>
      <b-col lg="3" class="my-1">
        <b-form-group
          label="Search"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col lg="3" class="my-1">
        <b-form-group
          label="Status"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="filter-status"
              v-model="statusFilter"
              :options="statusFilters"
            ></b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col lg="6" class="my-1" />
    </b-row>

    <div>
      <b-row>
        <b-col lg="3" class="my-1">
          <h4>Team Members</h4>
        </b-col>
        <b-col lg="3" class="my-1" />
        <b-col lg="2" class="my-1">
          <b-form-group
            v-if="filteredTeamMembers.length > minPaginationTeamMember"
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="teamMemberPerPage"
              :options="teamMemberPageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col lg="4" class="my-1">
          <b-pagination
            v-if="filteredTeamMembers.length > minPaginationTeamMember"
            v-model="teamMemberCurrentPage"
            :total-rows="filteredTeamMembers.length"
            :per-page="teamMemberPerPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
      <div v-if="filteredTeamMembers.length > 0">
        <div class="export-button-bar  mb-2 center">
          <b-button @click="showModal('Excel')" variant="info"
            >Export as Excel</b-button
          >
          <b-button
            @click="
              showModal('CSV');
              showDownloadNameModal = true;
            "
            variant="info"
            >Export as CSV</b-button
          >
          <b-modal
            v-model="showDownloadNameModal"
            :title="`Export as ${downloadFormat}`"
            ok-title="Save"
            @ok="exportToDownload"
            auto-focus-button="ok"
          >
            <b-container fluid>
              <label for="filename">Filename</label>
              <b-form-input
                v-model="exportFilename"
                id="filename"
                type="text"
              ></b-form-input>
            </b-container>
          </b-modal>
        </div>
        <b-table
          :items="filteredTeamMembers"
          :fields="teamMemberFields"
          :filter="filter"
          :filter-included-fields="teamMemberFilterOn"
          :current-page="teamMemberCurrentPage"
          :per-page="teamMemberPerPage"
          @filtered="onFiltered"
          sort-icon-left
          small
          responsive
        ></b-table>
      </div>
      <div v-else>
        <hr />
        <span class="center">No team members were found.</span>
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import XLSX from "xlsx";

export default {
  props: ["teamMembers"],
  data: function() {
    const initialTeamMemberPerPage = 100;
    return {
      defaultExportFilename: `team_members_${new Date().getFullYear()}_${new Date().getMonth() +
        1}_${new Date().getDate()}`,
      displayFileNameForm: false,
      showDownloadNameModal: false,
      downloadFormat: "",
      exportFilename: "",
      statusFilter: "live",
      statusFilters: [
        "All",
        "live",
        "archived"
      ],
      filter: null,
      teamMemberFields: [
        {
          key: "first_and_nick_name",
          label: "First Name",
          sortable: true,
        },
        {
          key: "last_name",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        {
          key: "phone_office",
          label: "Office",
          sortable: true,
        },
        {
          key: "phone_mobile",
          label: "Mobile",
          sortable: true,
        },
        {
          key: "location",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        {
          key: "account_status",
          label: "Account Status",
          sortable: true,
        },
        {
          key: "category_display_name",
          label: "Category",
          sortable: true,
        },
        {
          key: "team_member_classification",
          label: "Classification",
          sortable: true,
        },
        {
          key: "birth_month",
          label: "Birth Month",
          sortable: true,
        },
        {
          key: "birth_day",
          label: "Birth Day",
          sortable: true,
        },
        {
          key: "dietary_restrictions",
          label: "Dietary Restrictions",
          sortable: true,
        },
        {
          key: "working_remotely",
          label: "Working Remotely",
          sortable: true,
        },
        {
          key: "approval_limit",
          label: "Approval Limit",
          sortable: true,
        },
        {
          key: "advance_payment_approver",
          label: "Advance Payment Approver",
          formatter: (value) => value ? "Yes" : "No",
          filterByFormatted: true,
          sortable: true,
        },
        {
          key: "invoice_organization_approver",
          label: "Invoice Organization Approver",
          formatter: (value) => value ? "Yes" : "No",
          filterByFormatted: true,
          sortable: true,
        },
        {
          key: "role_name",
          label: "Role",
          sortable: true,
        },
        {
          key: "supervisors_object",
          label: "Supervisors",
          formatter: (value) => value?.map((supervisor) => supervisor.name).sort().join(", "),
          filterByFormatted: true,
          sortable: true,
        },
        {
          key: "live_supervises_object",
          label: "Supervises",
          formatter: (value) => value?.map((supervisee) => supervisee.name).sort().join(", "),
          filterByFormatted: true,
          sortable: true,
        },
        {
          key: "qualifications_object",
          label: "Qualifications",
          formatter: (value) => value?.join(", "),
          filterByFormatted: true,
          sortable: true,
        },
      ],
      teamMemberFilterOn: [
        "first_and_nick_name",
        "last_name",
        "email",
        "location",
        "phone_office",
        "phone_mobile",
        "alternate_names",
        "status",
        "account_status",
        "category_display_name",
        "team_member_classification",
        "birth_month",
        "birth_day",
        "dietary_restrictions",
        "working_remotely",
        "approval_limit",
        "advance_payment_approver",
        "invoice_organization_approver",
        "role_name",
        "supervisors_object",
        "live_supervises_object",
        "qualifications_object",
      ],
      teamMemberCurrentPage: 1,
      teamMemberPerPage: initialTeamMemberPerPage,
      teamMemberPageOptions: [
        10,
        20,
        50,
        100,
        { value: this.teamMembers.length, text: "Show all" },
      ],
      teamMemberTotalRows: this.teamMembers.length,
      minPaginationTeamMember: initialTeamMemberPerPage,
    };
  },
  computed: {
    filteredTeamMembers() {
      // When location filter changes, reset pagination page
      this.teamMemberCurrentPage = 1;
      if (this.statusFilter === "All") {
        return this.teamMembers.sort(this.sortRows);
      } else if (this.statusFilter === "None") {
        return this.teamMembers
          .filter(
            (teamMember) =>
              !teamMember.location || teamMember.location === "None"
          )
          .sort(this.sortRows);
      } else {
        return this.teamMembers
          .filter((teamMember) => teamMember.status === this.statusFilter)
          .sort(this.sortRows);
      }
    },
  },
  methods: {
    showModal(format) {
      this.downloadFormat = format;
      if (this.exportFilename === "") {
        this.exportFilename = this.defaultExportFilename;
      }
      this.showDownloadNameModal = true;
    },
    exportToDownload() {
      if (this.downloadFormat === "Excel") {
        this.exportToExcel();
      } else if (this.downloadFormat === "CSV") {
        this.exportToCSV();
      }
    },
    exportToExcel() {
      // export json to Worksheet of Excel
      // only array possible
      var teamMemberRecords = XLSX.utils.json_to_sheet(
        this.convertTeamMembersForExport(this.filteredTeamMembers)
      );

      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, teamMemberRecords, "Team Members");

      // export Excel file
      XLSX.writeFile(wb, `${this.exportFilename}.xlsx`);
    },
    exportToCSV() {
      // export json to Worksheet of Excel
      // only array possible
      var teamMemberRecords = XLSX.utils.json_to_sheet(
        this.convertTeamMembersForExport(this.filteredTeamMembers)
      );

      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, teamMemberRecords, "Team Members");

      // export CSV file
      XLSX.writeFile(wb, `${this.exportFilename}.csv`);
    },
    convertTeamMembersForExport(teamMembers) {
      return teamMembers.map((teamMember) => ({
        "First Name": teamMember.first_and_nick_name,
        "Last Name": teamMember.last_name,
        Email: teamMember.email,
        Office: teamMember.phone_office,
        Mobile: teamMember.phone_mobile,
        Location: teamMember.location,
        Status: teamMember.status,
        "Account Status": teamMember.account_status,
        Category: teamMember.category_display_name,
        Classification: teamMember.team_member_classification,
        "Birth Month": teamMember.birth_month,
        "Birth Day": teamMember.birth_day,
        "Dietary Restrictions": teamMember.dietary_restrictions,
        "Working Remotely": teamMember.working_remotely,
        "Approval Limit": teamMember.approval_limit,
        "Advance Payment Approver": teamMember.advance_payment_approver ? "Yes" : "No",
        "Invoice Organization Approver": teamMember.invoice_organization_approver ? "Yes" : "No",
        Role: teamMember.role_name,
        Supervisors: teamMember.supervisors_object?.map((supervisor) => supervisor.name).sort().join(", "),
        Supervises: teamMember.live_supervises_object?.map((supervisee) => supervisee.name).sort().join(", "),
        Qualifications: teamMember.qualifications_object?.join(", "),
      }));
    },
    onFiltered(filteredTeamMembers) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.teamMemberTotalRows = filteredTeamMembers.length;
      this.teamMemberCurrentPage = 1;
    },
  },
};
</script>

<style></style>
