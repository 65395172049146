<template>
  <div class="effort-list-export-table">
    <div v-if="teamMemberCount" class="text-center">
      Records from <strong>{{ teamMemberCount }}</strong> team member{{
        teamMemberCount !== 1 ? "s" : ""
      }}.
    </div>

    <div class="">
      <b-form-select v-model="filter" :options="filters"></b-form-select>
      <h3 class="center">Birthdays</h3>
      <b-table
        v-if="filteredItems.length > 0"
        :items="filteredItems"
        :fields="fields"
        sort-icon-left
        small
        responsive
      >
        <template #cell(birth_month)="data">
          {{ monthNameFromNumber(data.value) }}
        </template>
      </b-table>
      <div v-else>
        <hr />
        <span class="center">No team members were found.</span>
        <hr />
      </div>
    </div>
    <div class="export-button-bar  mb-2 center">
      <b-button @click="showModal('Excel')" variant="info"
        >Export as Excel</b-button
      >
      <b-button
        @click="
          showModal('CSV');
          showDownloadNameModal = true;
        "
        variant="info"
        >Export as CSV</b-button
      >
      <b-modal
        v-model="showDownloadNameModal"
        :title="`Export as ${downloadFormat}`"
        ok-title="Save"
        @ok="exportToDownload"
        auto-focus-button="ok"
      >
        <b-container fluid>
          <label for="filename">Filename</label>
          <b-form-input
            v-model="exportFilename"
            id="filename"
            type="text"
          ></b-form-input>
        </b-container>
      </b-modal>
    </div>
  </div>
</template>

<script>
import XLSX from "xlsx";

export default {
  props: ["items", "teamMemberCount"],
  data: function() {
    return {
      defaultExportFilename: `birthdays_${new Date().getFullYear()}_${new Date().getMonth() +
        1}_${new Date().getDate()}`,
      displayFileNameForm: false,
      showDownloadNameModal: false,
      downloadFormat: "",
      exportFilename: "",
      filter: "All",
      filters: [
        "All",
        ...new Set(
          this.items
            .filter((item) => item.location)
            .map((item) => item.location)
        ),
        "Not Set",
      ],
      fields: [
        {
          key: "first_and_nick_name",
          label: "First Name",
        },
        {
          key: "last_name",
        },
        {
          key: "location",
        },
        {
          key: "birth_month",
          label: "Month",
        },
        {
          key: "birth_day",
          label: "Day",
        },
        {
          key: "dietary_restrictions",
        },
        {
          key: "working_remotely",
        },
      ],
    };
  },
  computed: {
    filteredItems() {
      if (this.filter === "All") {
        return this.items.sort(this.sortRows);
      } else if (this.filter === "Not Set") {
        return this.items.filter((item) => !item.location).sort(this.sortRows);
      } else {
        return this.items
          .filter((item) => item.location === this.filter)
          .sort(this.sortRows);
      }
    },
    filteredItemsCount() {
      this.filteredItems.length;
    },
  },
  methods: {
    showModal(format) {
      this.downloadFormat = format;
      if (this.exportFilename === "") {
        this.exportFilename = this.defaultExportFilename;
      }
      this.showDownloadNameModal = true;
    },
    exportToDownload() {
      if (this.downloadFormat === "Excel") {
        this.exportToExcel();
      } else if (this.downloadFormat === "CSV") {
        this.exportToCSV();
      }
    },
    exportToExcel() {
      // export json to Worksheet of Excel
      // only array possible
      var birthdayRecords = XLSX.utils.json_to_sheet(
        this.convertItemsForExport(this.filteredItems)
      );

      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, birthdayRecords, "Birthdays");

      // export Excel file
      XLSX.writeFile(wb, `${this.exportFilename}.xlsx`);
    },
    exportToCSV() {
      // export json to Worksheet of Excel
      // only array possible
      var birthdayRecords = XLSX.utils.json_to_sheet(
        this.convertItemsForExport(this.filteredItems)
      );

      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, birthdayRecords, "Birthdays");

      // export CSV file
      XLSX.writeFile(wb, `${this.exportFilename}.csv`);
    },
    convertItemsForExport(items) {
      return items.map((item) => ({
        "First Name": item.first_and_nick_name,
        "Last Name": item.last_name,
        Location: item.location,
        Month: this.monthNameFromNumber(item.birth_month),
        Day: item.birth_day,
        "Dietary Restrictions": item.dietary_restrictions,
        "Working Remotely": item.working_remotely,
      }));
    },
    sortRows(a, b) {
      try {
        // Check for birth month data, sort blank to top
        if (isNaN(parseInt(a.birth_month))) {
          // Not sort field if both are NaN
          if (!isNaN(parseInt(b.birth_month))) {
            return -1;
          }
        } else if (isNaN(parseInt(b.birth_month))) {
          return 1;
        }

        // Sort by month asc
        if (a.birth_month !== b.birth_month) {
          return parseInt(a.birth_month) - parseInt(b.birth_month);
        }
        // Check for birth day data, sort blank to top
        if (isNaN(parseInt(a.birth_day))) {
          // Not sort field if both are NaN
          if (!isNaN(parseInt(b.birth_day))) {
            return -1;
          }
        } else if (isNaN(parseInt(b.birth_day))) {
          return 1;
        }

        // Sort by day asc
        if (a.birth_day !== b.birth_day) {
          return parseInt(a.birth_day) - parseInt(b.birth_day);
        }

        // Sort by first and nick name asc
        if (a.first_and_nick_name !== b.first_and_nick_name) {
          return a.first_and_nick_name.localeCompare(b.first_and_nick_name);
        }

        // Sort by last name asc
        if (a.last_name !== b.last_name) {
          return a.last_name.localeCompare(b.last_name);
        }
      } catch (err) {
        console.log(`Failed to sort: ${err}`);
        return 0;
      }
    },
    monthNameFromNumber(monthNumber) {
      try {
        // Check if monthNumber is a number
        if (isNaN(parseInt(monthNumber))) {
          return "";
        }
        const date = new Date();
        // Set the day to the 2nd of the month to not worry about overflow or timezone quirks.
        date.setDate(2);
        date.setMonth(parseInt(monthNumber) - 1);
        return date.toLocaleString("default", { month: "long" });
      } catch (err) {
        console.log(`Couldn't convert month number to name: ${err}`);
        return "";
      }
    },
  },
};
</script>

<style></style>
