<template>
  <div class="advanced-hours-report-page">
    <h1>Advanced Hours Report</h1>
    <div style="width: 20%; margin: 0 auto; margin-bottom: 1rem;">
      <label>Start Date</label>
      <b-form-datepicker @keydown.native.enter="getReport" v-model="dateStart" class="mb-2"></b-form-datepicker>
      <label>End Date</label>
      <b-form-datepicker @keydown.native.enter="getReport" v-model="dateEnd" class="mb-2"></b-form-datepicker>
      <b-form-select @keydown.native.enter="getReport" v-model="selectedPeople" :options="people" multiple :select-size="Math.min(10, people.length)"/>
      <b-form-select @keydown.native.enter="getReport" v-model="selectedEfforts" :options="efforts" multiple :select-size="Math.min(10, efforts.length)"/>
      <div class="center">
        <b-button v-if="!querying" @click="getReport" variant="outline-primary">Query</b-button>
        <b-button v-else variant="outine-primary"><b-spinner small variant="primary" label="Text Centered"></b-spinner></b-button>
      </div>
    </div>
    <div v-if="queried && !querying">
      <h3 class="text-center">Results</h3>
      <hours-report-export-table v-if="report.length > 0" :items="report" small />
      <p class="center" v-else-if="error !== ''">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import HoursReportExportTable from "./HoursReportExportTable.vue"

export default {
  props: {people: {default: function () { return [] }}, efforts: {default: function () { return [] }}},
  components: {
    "hours-report-export-table": HoursReportExportTable
  },
  data: function () {
    return {
      dateStart: null,
      dateEnd: null,
      selectedPeople: [],
      selectedEfforts: [],
      report: [],
      error: "",
      querying: false,
      queried: false
    }
  },
  methods: {
    getCSRFToken() {
      return document.querySelector('[name=csrf-token]').content
    },
    setRequestHeaders() {
      axios.defaults.headers.common['X-CSRF-TOKEN'] = this.getCSRFToken();
      axios.defaults.headers.common['Accept'] = 'application/json';
      axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    },
    getReport() {
      this.querying = true
      this.report = []
      this.error = ""
      this.setRequestHeaders()
      axios.post("/allocator/advanced_hours_report_request", {date_start: this.dateStart, date_end: this.dateEnd, team_member_id: this.selectedPeople, effort_id: this.selectedEfforts}).then(response => {
        if ("report" in response.data) {
          this.report = response.data.report
        } else if ("error" in response.data) {
          this.error = response.data.error
        } else {
          this.error = "Unrecognized response from server. Please try again."
        }
      }).catch(response => {
        console.log("There was an issue reaching the server. Please try again.");
      }).finally(() => {
        this.querying = false
        this.queried = true
      })
    }
  }
}
</script>

<style>
</style>
