<template>
  <div class="effort-list-export-table">
    <div class="export-button-bar  mb-2 center">
      <b-button @click="showModal('Excel')" variant="info"
        >Export as Excel</b-button
      >
      <b-button
        @click="
          showModal('CSV');
          showDownloadNameModal = true;
        "
        variant="info"
        >Export as CSV</b-button
      >
      <b-modal
        v-model="showDownloadNameModal"
        :title="`Export as ${downloadFormat}`"
        ok-title="Save"
        @ok="exportToDownload"
        auto-focus-button="ok"
      >
        <b-container fluid>
          <label for="filename">Filename</label>
          <b-form-input
            v-model="exportFilename"
            id="filename"
            type="text"
          ></b-form-input>
        </b-container>
      </b-modal>
    </div>
    <div v-if="teamMemberCount" class="text-center">
      Records from <strong>{{ teamMemberCount }}</strong> team member{{
        teamMemberCount !== 1 ? "s" : ""
      }}.
    </div>

    <div class="">
      <b-form-select v-model="filter" :options="filters"></b-form-select>
      <h3 class="center">Qualifications</h3>
      <b-table
        v-if="filteredItems.length > 0"
        :items="filteredItems"
        :fields="fields"
        sort-icon-left
        small
        responsive
      ></b-table>
      <h3 class="center">Summary</h3>
      <b-table
        v-if="locationItems.length > 0"
        :items="locationItems"
        :fields="locationFields"
        sort-icon-left
        small
        responsive
      ></b-table>
      <div v-else>
        <hr />
        <span class="center">No team members were found.</span>
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import XLSX from "xlsx";

export default {
  props: ["items", "qualifications", "teamMemberCount"],
  data: function() {
    return {
      defaultExportFilename: `qualifications_audit_${new Date().getFullYear()}_${new Date().getMonth() +
        1}_${new Date().getDate()}`,
      displayFileNameForm: false,
      showDownloadNameModal: false,
      downloadFormat: "",
      exportFilename: "",
      filter: "all",
      filters: [
        { value: "all", text: "All" },
        { value: "archived", text: "Archived" },
        { value: "live", text: "Live" },
      ],
      fields: [
        {
          key: "id",
          sortable: true,
        },
        {
          key: "first_name",
          sortable: true,
        },
        {
          key: "last_name",
          sortable: true,
        },
        {
          key: "location",
          sortable: true,
        },
        {
          key: "status",
          sortable: true,
        },
        {
          key: "US Person",
          sortable: true,
        },
        {
          key: "US Citizen",
          sortable: true,
        },
        {
          key: "Secret Clearance",
          sortable: true,
        },
        {
          key: "Top Secret Clearance",
          sortable: true,
        },
      ],
      locationFields: [
        {
          key: "location",
          sortable: true,
        },
        {
          key: "US Person",
          sortable: true,
        },
        {
          key: "US Citizen",
          sortable: true,
        },
        {
          key: "Secret Clearance",
          sortable: true,
        },
        {
          key: "Top Secret Clearance",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    filteredItems() {
      if (this.filter === "archived") {
        return this.items
          .filter((item) => item.status === "archived")
          .map((item) => {
            const clone = { ...item };
            delete clone.qualifications;
            this.qualifications.forEach((qualification) => {
              const name = qualification.name;
              clone[name] = item.qualifications.includes(name) ? name : "";
            });
            return clone;
          });
      } else if (this.filter === "live") {
        return this.items
          .filter((item) => item.status === "live")
          .map((item) => {
            const clone = { ...item };
            delete clone.qualifications;
            this.qualifications.forEach((qualification) => {
              const name = qualification.name;
              clone[name] = item.qualifications.includes(name) ? name : "";
            });
            return clone;
          });
      } else {
        return this.items.map((item) => {
          const clone = { ...item };
          delete clone.qualifications;
          this.qualifications.forEach((qualification) => {
            const name = qualification.name;
            clone[name] = item.qualifications.includes(name) ? name : "";
          });
          return clone;
        });
      }
    },
    filteredItemsCount() {
      this.filteredItems.length;
    },
    locationItems() {
      return this.getLocationSummary(this.filteredItems);
    },
  },
  methods: {
    showModal(format) {
      this.downloadFormat = format;
      if (this.exportFilename === "") {
        this.exportFilename = this.defaultExportFilename;
      }
      this.showDownloadNameModal = true;
    },
    getLocationSummary(items) {
      if (items.length == 0) {
        return [];
      }
      const locationItems = {};
      items.forEach((item) => {
        var locationItem = null;
        if (item.location && item.location != "") {
          if (!(item.location in locationItems)) {
            locationItems[item.location] = {
              location: item.location,
              "US Person": 0,
              "US Citizen": 0,
              "Secret Clearance": 0,
              "Top Secret Clearance": 0,
            };
          }
          locationItem = locationItems[item.location];
        } else {
          if (!("Unknown" in locationItems)) {
            locationItems["Unknown"] = {
              location: "Unknown",
              "US Person": 0,
              "US Citizen": 0,
              "Secret Clearance": 0,
              "Top Secret Clearance": 0,
            };
          }
          locationItem = locationItems["Unknown"];
        }
        if (item["US Person"]) {
          locationItem["US Person"] += 1;
        }
        if (item["US Citizen"]) {
          locationItem["US Citizen"] += 1;
        }
        if (item["Secret Clearance"]) {
          locationItem["Secret Clearance"] += 1;
        }
        if (item["Top Secret Clearance"]) {
          locationItem["Top Secret Clearance"] += 1;
        }
      });
      return Object.keys(locationItems)
        .sort()
        .map((locationItem) => locationItems[locationItem]);
    },
    exportToDownload() {
      if (this.downloadFormat === "Excel") {
        this.exportToExcel();
      } else if (this.downloadFormat === "CSV") {
        this.exportToCSV();
      }
    },
    exportToExcel() {
      // export json to Worksheet of Excel
      // only array possible
      var liveTeamMemberRecords = XLSX.utils.json_to_sheet(
        this.items
          .filter((item) => item.status === "live")
          .map((item) => {
            const clone = { ...item };
            delete clone.qualifications;
            this.qualifications.forEach((qualification) => {
              const name = qualification.name;
              clone[name] = item.qualifications.includes(name) ? name : "";
            });
            return clone;
          })
      );
      var liveTeamMemberSummary = XLSX.utils.json_to_sheet(
        this.getLocationSummary(
          this.items
            .filter((item) => item.status === "live")
            .map((item) => {
              const clone = { ...item };
              delete clone.qualifications;
              this.qualifications.forEach((qualification) => {
                const name = qualification.name;
                clone[name] = item.qualifications.includes(name) ? name : "";
              });
              return clone;
            })
        )
      );
      var archivedTeamMemberRecords = XLSX.utils.json_to_sheet(
        this.items
          .filter((item) => item.status === "archived")
          .map((item) => {
            const clone = { ...item };
            delete clone.qualifications;
            this.qualifications.forEach((qualification) => {
              const name = qualification.name;
              clone[name] = item.qualifications.includes(name) ? name : "";
            });
            return clone;
          })
      );
      var archivedTeamMemberSummary = XLSX.utils.json_to_sheet(
        this.getLocationSummary(
          this.items
            .filter((item) => item.status === "archived")
            .map((item) => {
              const clone = { ...item };
              delete clone.qualifications;
              this.qualifications.forEach((qualification) => {
                const name = qualification.name;
                clone[name] = item.qualifications.includes(name) ? name : "";
              });
              return clone;
            })
        )
      );

      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, liveTeamMemberSummary, "Live - Summary");
      XLSX.utils.book_append_sheet(wb, liveTeamMemberRecords, "Live");
      XLSX.utils.book_append_sheet(
        wb,
        archivedTeamMemberSummary,
        "Archived - Summary"
      );
      XLSX.utils.book_append_sheet(wb, archivedTeamMemberRecords, "Archived");

      // export Excel file
      XLSX.writeFile(wb, `${this.exportFilename}.xlsx`);
    },
    exportToCSV() {
      // export json to Worksheet of Excel
      // only array possible
      var effortRecords = XLSX.utils.json_to_sheet(
        this.items.map((item) => {
          const clone = { ...item };
          delete clone.qualifications;
          this.qualifications.forEach((qualification) => {
            const name = qualification.name;
            clone[name] = item.qualifications.includes(name) ? name : "";
          });
          return clone;
        })
      );

      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, effortRecords, "Projects");

      // export CSV file
      XLSX.writeFile(wb, `${this.exportFilename}.csv`);
    },
  },
};
</script>

<style></style>
