<template>
  <div class="pto-monthly-transactions-export-table">
    <div class="export-button-bar  mb-2 center">
      <b-button @click="showModal('Excel')" variant="info">Export as Excel</b-button>
      <b-button @click="showModal('CSV'); showDownloadNameModal = true;" variant="info">Export as CSV</b-button>
      <b-modal
        v-model="showDownloadNameModal"
        :title="`Export as ${downloadFormat}`"
        ok-title="Save"
        @ok="exportToDownload"
        auto-focus-button="ok"
      >
        <b-container fluid>
          <label for="filename">Filename</label>
          <b-form-input v-model="exportFilename" id="filename" type="text"></b-form-input>
        </b-container>
      </b-modal>
    </div>

    <div class="mx-4">
      <h3>Summary</h3>
      <b-table :items="items" :fields="formattedFields" small></b-table>
    </div>

    <div class="mx-4">
      <h3>Transactions</h3>
      <b-table :items="transactionsItems" :fields="formattedTransactionsFields" small></b-table>
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx'

export default {
  props: ['fields', 'items', 'setName', 'transactionsFields', 'transactionsItems', 'transactionsSetName', 'dateStart', 'dateEnd'],
  data: function () {
    return {
      defaultExportFilename: `pto_transactions_summary_${this.dateStart}-${this.dateEnd}`,
      displayFileNameForm: false,
      showDownloadNameModal: false,
      downloadFormat: "",
      exportFilename: ""
    }
  },
  computed: {
    formattedFields: function () {
      var formattedFields = []
      this.fields.forEach((field) => {
        if (typeof field === typeof {}) {
          if (!field.formatter) {
            field.formatter = (value) => (typeof value !== "number" || value === "") ? value : Math.round(value * 100) / 100;
          }
          formattedFields.push(field);
        } else {
          var fieldObject = {key: field, label: field, formatter: (value) => (typeof value !== "number" || value === "") ? value : Math.round(value * 100) / 100};
          formattedFields.push(fieldObject);
        }
      });
      return formattedFields
    },
    formattedTransactionsFields: function () {
      var formattedFields = []
      this.transactionsFields.forEach((field) => {
        if (typeof field === typeof {}) {
          if (!field.formatter) {
            field.formatter = (value) => (typeof value !== "number" || value === "") ? value : Math.round(value * 100) / 100;
          }
          formattedFields.push(field);
        } else {
          var fieldObject = {key: field, label: field, formatter: (value) => (typeof value !== "number" || value === "") ? value : Math.round(value * 100) / 100};
          formattedFields.push(fieldObject);
        }
      });
      return formattedFields
    },
    orderedFields: function () {
      var orderedFields = []
      this.formattedFields.forEach((field) => {
        if (typeof field === typeof {}) {
          orderedFields.push(field.key)
        } else {
          orderedFields.push(field)
        }
      });
      return orderedFields
    },
    orderedTransactionFields: function () {
      var orderedFields = []
      this.formattedTransactionsFields.forEach((field) => {
        if (typeof field === typeof {}) {
          orderedFields.push(field.key)
        } else {
          orderedFields.push(field)
        }
      });
      return orderedFields
    }
  },
  methods: {
    showModal(format) {
      this.downloadFormat = format;
      if (this.exportFilename === "") {
        this.exportFilename = this.defaultExportFilename;
      }
      this.showDownloadNameModal = true;
    },
    exportToDownload() {
      if (this.downloadFormat === "Excel") {
        this.exportToExcel()
      } else if (this.downloadFormat === "CSV") {
        this.exportToCSV()
      }
    },
    exportToExcel() {
      // export json to Worksheet of Excel
      // only array possible
      var ptoSummaries = XLSX.utils.json_to_sheet(this.items, {header: this.orderedFields})
      var ptoTransactions = XLSX.utils.json_to_sheet(this.transactionsItems, {header: this.orderedTransactionFields})

      var wb = XLSX.utils.book_new() // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, ptoSummaries, this.setName)
      XLSX.utils.book_append_sheet(wb, ptoTransactions, this.transactionsSetName)

      // export Excel file
      XLSX.writeFile(wb, `${this.exportFilename}.xlsx`)
    },
    exportToCSV() {
      // export json to Worksheet of Excel
      // only array possible
      var ptoRecords = XLSX.utils.json_to_sheet(this.items, {header: this.orderedFields})
      var ptoTransactions = XLSX.utils.json_to_sheet(this.transactionsItems, {header: this.orderedTransactionFields})

      var wb = XLSX.utils.book_new() // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, ptoRecords, this.setName)
      XLSX.utils.book_append_sheet(wb, ptoTransactions, this.transactionsSetName)

      // export CSV file
      XLSX.writeFile(wb, `${this.exportFilename}.csv`)
    }
  }
}
</script>

<style>
</style>
